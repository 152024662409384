import { FC, useRef } from 'react'
import { Button, FormInput } from '@aurecon-creative-technologies/styleguide'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import {
  ChatSession,
  QuestionInput,
  LoadingAnswer,
  ShowExtendedInput,
  QuestionFile,
  AssistantResponse,
} from '../stores/AppStore'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ChatTypeEnum, ChatTypeToPrompt } from '../enums/ChatTypeEnums'
import { UI_WIDTH_COLLAPSE } from '../config/config'

import Style from '../styles/SubmitButton.module.sass'
import { processFileToAssistant } from '../api/AssistantService'

interface ISubmitButtonProps {
  chatType: number
  fileUploadRef: React.RefObject<HTMLInputElement>
  imageUploadRef: React.RefObject<HTMLInputElement>
}

let controller = new AbortController()

const ENTER_KEY = 'Enter'

const SubmitButton: FC<ISubmitButtonProps> = (props) => {
  const [chatSession] = useRecoilState(ChatSession)
  const setResponse = useSetRecoilState(AssistantResponse)
  const [questionInput, setQuestionInput] = useRecoilState(QuestionInput)
  const questionFile = useRecoilValue(QuestionFile)

  const textInputRef = useRef<HTMLInputElement>(null)
  const shift = false
  const setLoadingAnswer = useSetRecoilState(LoadingAnswer)
  const setShowExtendedInput = useSetRecoilState(ShowExtendedInput)

  const loading = chatSession?.questions.some((q) => !!q.loading)
  const homePage = !chatSession?.questions.length
  const prompts = ChatTypeToPrompt[ChatTypeEnum.GPT]

  const placeholderText = homePage ? prompts.start : prompts.follow
  const placeholder = loading ? prompts.loading : placeholderText

  const { chatType } = props

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const submitButtonProps = isDesktop
    ? { type: 'primary' as 'icon-square' | 'primary', label: loading ? 'Stop' : 'Submit' }
    : { type: 'icon-square' as 'icon-square' | 'primary', icon: loading ? 'stop' : 'send' }

  const submitButtonClasses = classNames({
    [Style.submitButton]: true,
    [Style.chatGpt]: chatType === ChatTypeEnum.GPT,
  })

  const stopGenerating = () => {
    console.log('Aborting...')
    controller.abort()
  }

  const handleQuestionSubmit = async (key?: string) => {
    if ((key && key !== ENTER_KEY) || shift) return
    if (!questionInput.trim()) return
    if (!questionFile) return
    const res = await processFileToAssistant({ file: questionFile[0], message: questionInput })
    if (res) {
      //console.log('Assistant Response::' + res.data?.assistantResponse.data[0]?.content[0].text.value)
      const textValue = res.data?.assistantResponse.data[0]?.content[0].text.value
      if (textValue) {
        setResponse(textValue)
      }
    } else setResponse('')
    setLoadingAnswer(true)
    controller = new AbortController()
    setShowExtendedInput(false)
  }

  return (
    <>
      <FormInput
        placeholder={placeholder}
        cssClass={Style.input}
        value={questionInput}
        onChange={setQuestionInput}
        disabled={loading}
        onKeyDown={(key) => handleQuestionSubmit(key)}
        multiline
        ref={textInputRef}
      />
      <span>
        <Button
          {...submitButtonProps}
          cssClass={submitButtonClasses}
          onClick={loading ? stopGenerating : () => handleQuestionSubmit()}
        />
      </span>
    </>
  )
}

export default SubmitButton
