export const FileMimeType = {
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  WEBP: 'image/webp',
  PJP: 'image/pjp',
  PJPEG: 'image/pjpeg',
  JFIF: 'image/jfif',
  SVG: 'image/svg+xml',
  MP4: 'video/mp4',
  M4V: 'video/m4v',
  WEBM: 'video/webm',
  OGM: 'video/ogm',
  OGV: 'video/ogv',
  PDF: 'application/pdf',
  VIDEO_WEBM: 'application/webm',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC: 'application/msword',
  RTF: 'application/rtf',
  TXT: 'text/plain',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS: 'application/vnd.ms-excel',
  CSV: 'text/csv',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPT: 'application/vnd.ms-powerpoint',
  C: 'text/x-c',
  CPP: 'text/x-c++',
  HTML: 'text/html',
  JAVA: 'text/x-java',
  JSON: 'application/json',
  MD: 'text/markdown',
  PHP: 'text/x-php',
  PY: 'text/x-python',
  RB: 'text/x-ruby',
  TEX: 'text/x-tex',
  TS: 'text/typescript',
  CSS: 'text/css',
  JS: 'text/javascript',
  TAR: 'application/x-tar',
  XML: 'text/xml',
  ZIP: 'application/zip',
}
export const ContentTypeToRealFileExtn = {
  [FileMimeType.JPG]: 'jpg',
  [FileMimeType.JPEG]: 'jpeg',
  [FileMimeType.PNG]: 'png',
  [FileMimeType.GIF]: 'gif',
  [FileMimeType.WEBP]: 'webp',
  [FileMimeType.PJP]: 'pjp',
  [FileMimeType.PJPEG]: 'pjpeg',
  [FileMimeType.JFIF]: 'jfif',
  [FileMimeType.SVG]: 'svg',
  [FileMimeType.MP4]: 'mp4',
  [FileMimeType.M4V]: 'm4v',
  [FileMimeType.WEBM]: 'webm',
  [FileMimeType.OGM]: 'ogm',
  [FileMimeType.OGV]: 'ogv',
  [FileMimeType.PDF]: 'pdf',
  [FileMimeType.VIDEO_WEBM]: 'webm',
  [FileMimeType.DOCX]: 'docx',
  [FileMimeType.DOC]: 'doc',
  [FileMimeType.RTF]: 'application/rtf',
  [FileMimeType.TXT]: 'txt',
  [FileMimeType.XLSX]: 'xlsx',
  [FileMimeType.XLS]: 'xls',
  [FileMimeType.CSV]: 'csv',
  [FileMimeType.PPTX]: 'pptx',
  [FileMimeType.PPT]: 'ppt',
}
