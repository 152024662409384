import { ATTACHMENT_EXT, MAX_ATTACHMENT_SIZE, MAX_NO_ATTACHMENTS } from '../config/config'

export const ChatTypeEnum = {
  GPT: 1,
}

export const ChatTypeInput = {
  [ChatTypeEnum.GPT]: { focus: true, file: true, fileDrop: true },
}

export const ChatTypeConfig = {
  [ChatTypeEnum.GPT]: { maxSize: MAX_ATTACHMENT_SIZE, maxFiles: MAX_NO_ATTACHMENTS, extensions: ATTACHMENT_EXT },
}

export const ChatTypeToPrompt = {
  [ChatTypeEnum.GPT]: {
    start: 'Ask a question...',
    follow: 'Ask a follow up question...',
    loading: 'Generating an answer...',
  },
}
