import { selector, atom } from 'recoil'
import { getAppVersion } from '../api/AppService'
import { IChatSession, IFocusItem } from '../models/IQuestionsModels'

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = await getAppVersion()
    return response.data?.version
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const ShowExtendedInput = atom({
  key: 'show_extended_input',
  default: false,
})

export const FullScreen = atom({
  key: 'full_screen',
  default: true,
})

export const ChatType = atom({
  key: 'chat_type',
  default: null as number | null,
})

export type ChatTypeConfigType = {
  [key: number]: {
    maxFiles: number
    maxSize: number
    extensions: string[]
  }
}
export const ChatTypeConfig = atom<ChatTypeConfigType>({
  key: 'chatTypeConfig',
  default: {},
})

export const ChatSession = atom<IChatSession | null>({
  key: 'chat_session',
  default: null,
})

export const QuestionFile = atom({
  key: 'question_file',
  default: null as File[] | null,
})

export const SelectedFocus = atom<IFocusItem[]>({
  key: 'selected_focus',
  default: [],
})

export const QuestionInput = atom({
  key: 'question_input',
  default: '',
})

export const LoadingAnswer = atom<boolean>({
  key: 'loading_answer',
  default: false,
})

export const AssistantResponse = atom<string>({
  key: 'assistant_response',
  default: '',
})
