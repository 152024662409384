import { FC, useEffect } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import Page from '../components/Page'
import Style from '../styles/Home.module.sass'
import QuestionBox from '../components/QuestionBox'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ChatTypeEnum } from '../enums/ChatTypeEnums'
import { AssistantResponse, ChatType } from '../stores/AppStore'

const ChatGptHome: FC = () => {
  const setChatType = useSetRecoilState(ChatType)
  const response = useRecoilValue(AssistantResponse)
  useEffect(() => {
    setChatType(ChatTypeEnum.GPT)
    return () => setChatType(null)
  }, [setChatType])

  return (
    <Page menu>
      {response ? (
        <div className={Style.response}>
          <h3>Response</h3>
          <p>{response}</p>
        </div>
      ) : (
        <div className={Style.pageContents}>
          <h1 className={Style.chatTitle}>Aurecon Interpreter</h1>
          <p>Interpreter is Aurecon's Code Interpreter for analysing files.</p>
          <p>
            Interpreter has the ability to write code on your behalf to analyse your files. All this, while maintaining
            confidentiality and privacy, as your files and interactions are hosted within Aurecon's digital
            infrastructure.
          </p>
          <p>Upload a file and ask a question about its content below to get started.</p>
          <div className={Style.examples}>
            <h3>Navigation Tips</h3>
            <Grid row>
              <div className={Style.tip}>
                <p>
                  <Icon type='post_add' outlined />
                </p>
              </div>
              <div className={Style.tip}>
                <p>Upload a document to analyse with Interpreter</p>
              </div>
            </Grid>
            <Grid row>
              <div className={Style.tip}>
                <p>
                  <Icon type='keyboard' outlined />
                </p>
              </div>
              <div>
                <p>
                  Use ENTER to submit your query
                  <br />
                  Use SHIFT + ENTER to add new line to your query
                  <br />
                  Use SHIFT + ARROW UP / DOWN to bring previous queries
                </p>
                <p>{response}</p>
              </div>
            </Grid>
          </div>
        </div>
      )}
      <QuestionBox />
    </Page>
  )
}
export default ChatGptHome
