import { FC, useEffect, useRef, useState } from 'react'
import { Button, Tooltip } from '@aurecon-creative-technologies/styleguide'
import { ATTACHMENT_FILETYPES, MAX_NO_ATTACHMENTS, TOOLTIP_NO_OFFSET } from '../config/config'
import { IHandleFileChangeProps } from './QuestionBox'
import { useRecoilValue } from 'recoil'
import { QuestionFile, FullScreen } from '../stores/AppStore'

import Style from '../styles/FileAttachmentButton.module.sass'
import classNames from 'classnames'
import { ChatTypeEnum } from '../enums/ChatTypeEnums'

interface IFileAttachmentButtonProps {
  chatType: number
  disabled: boolean
  fileUploadRef: React.RefObject<HTMLInputElement>
  handleFileChange: (props: IHandleFileChangeProps) => void
}

const FileAttachmentButton: FC<IFileAttachmentButtonProps> = (props) => {
  const questionFile = useRecoilValue(QuestionFile)
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const [offset, setOffset] = useState(TOOLTIP_NO_OFFSET)
  const fullScreen = useRecoilValue(FullScreen)

  const { chatType, disabled, fileUploadRef, handleFileChange } = props

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    handleFileChange({ files: e.target.files, chatType })
  }

  const handleFileSelection = () => {
    if (!fileUploadRef.current) return

    fileUploadRef.current.click()
  }

  const files = questionFile?.length || 0
  const disableButton = disabled || files > MAX_NO_ATTACHMENTS - 1

  useEffect(() => {
    const handleResize = () => {
      if (!buttonRef?.current) return

      const position = buttonRef.current.getBoundingClientRect()
      const preview = (questionFile?.length || 0) > 0

      setOffset({ top: preview ? -position.top + 40 : -position.top, left: -position.left + (fullScreen ? 22 : 15) })
    }
    window.addEventListener('resize', handleResize)

    setTimeout(handleResize, 300)
  }, [questionFile?.length, fullScreen])

  const counterClasses = classNames({
    [Style.filterCounter]: true,
    [Style.chatGpt]: chatType === ChatTypeEnum.GPT,
  })

  const renderTooltipText = () => {
    if (files > MAX_NO_ATTACHMENTS - 1) return `Maximum ${MAX_NO_ATTACHMENTS} files`

    return 'Upload file'
  }

  return (
    <div ref={buttonRef}>
      <Tooltip cssClass={Style.tooltipWrapper} show={renderTooltipText()} defaultUp offset={offset}>
        <div className={Style.fileAttachButtonWrapper}>
          <Button
            cssClass={Style.fileAttachButton}
            type='icon-round'
            default
            icon='post_add'
            onClick={handleFileSelection}
            disabled={disableButton}
          />
          {!!files && <div className={counterClasses}>{files}</div>}
        </div>
      </Tooltip>
      <input
        ref={fileUploadRef}
        accept={ATTACHMENT_FILETYPES.join(', ')}
        id='file-upload'
        type='file'
        style={{ display: 'none' }}
        onChange={handleFile}
      />
    </div>
  )
}

export default FileAttachmentButton
