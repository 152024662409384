import { FC, useRef, DragEvent, useState, ClipboardEvent } from 'react'
import classNames from 'classnames'
import Style from '../styles/QuestionBox.module.sass'
import { useRecoilState, useRecoilValue } from 'recoil'
import { FullScreen, ShowExtendedInput, ChatType, ChatSession, QuestionFile } from '../stores/AppStore'
import { ChatTypeInput, ChatTypeConfig, ChatTypeEnum } from '../enums/ChatTypeEnums'
import { Icon, useToast } from '@aurecon-creative-technologies/styleguide'
import { MAX_NO_ATTACHMENTS } from '../config/config'
import { fileExtension, fileSize } from '../helpers/fileUtils'
import FocusButton from './FocusButton'
import FileAttachmentButton from './FileAttachmentButton'
import SubmitButton from './SubmitButton'

export interface IHandleFileChangeProps {
  chatType: number
  files: FileList
}

const QuestionBox: FC = () => {
  const questionBoxRef = useRef<HTMLInputElement>(null)
  const showExtendedInput = useRecoilValue(ShowExtendedInput)
  const fullScreen = useRecoilValue(FullScreen)
  const chatType = useRecoilValue(ChatType)
  const chatSession = useRecoilValue(ChatSession)
  //const chatTypeConfig = useRecoilValue(ChatTypeConfig)
  const [dropArea, setDropArea] = useState(0)
  const [questionFile, setQuestionFile] = useRecoilState(QuestionFile)
  const { addToast } = useToast()
  const fileUploadRef = useRef<HTMLInputElement>(null)
  const imageUploadRef = useRef<HTMLInputElement>(null)
  const loading = chatSession?.questions.some((q) => !!q.loading)

  if (chatType === null) return null
  const wrapperClasses = classNames({
    [Style.questionInputWrapper]: true,
    [Style.toOpen]: showExtendedInput,
    [Style.fullScreen]: fullScreen,
  })

  const handleFileChange = (props: IHandleFileChangeProps) => {
    if (!props.files?.length || !props.chatType) return
    const currentFiles = questionFile || []
    if (currentFiles.length + props.files.length > 3) {
      addToast({
        type: 'warning',
        message: `You can only upload a maximum of ${MAX_NO_ATTACHMENTS} files`,
        timeout: 5000,
      })
    }
    const { maxFiles, maxSize, extensions } = ChatTypeConfig[props.chatType]
    const files = Array.from(props.files).filter((file) => {
      const fileExt = fileExtension(file.name)
      if (!extensions.some((ext: string) => ext === fileExt)) {
        addToast({
          type: 'error',
          message: `Incorrect file: ${file.name}.`,
          timeout: 5000,
        })
        return false
      }
      if (file.size > maxSize) {
        addToast({
          type: 'error',
          message: `File size exceeds the maximum limit of  ${fileSize(maxSize)}`,
          timeout: 5000,
        })
        return false
      }
      return true
    })
    setQuestionFile([...currentFiles, ...files].splice(0, maxFiles))
  }

  const handleDropEnter = (e: DragEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop || loading) return
    setDropArea((v) => v + 1)

    e.preventDefault()
    e.stopPropagation()
  }

  const handleDropLeave = (e: DragEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop) return
    setDropArea((v) => v - 1)

    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop) return
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    if (loading) {
      setDropArea(0)
      return
    }

    if (!chatType || !ChatTypeInput[chatType].fileDrop) return
    setDropArea(0)

    handleFileChange({
      files: e.dataTransfer.files,
      chatType,
    })
    e.preventDefault()
    e.stopPropagation()
  }

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop || loading) return
    setDropArea(0)

    handleFileChange({
      files: e.clipboardData.files,
      chatType,
    })

    e.stopPropagation()
  }

  const renderAddFileArea = () => {
    //currently the if condition is true and returns null
    if (loading || !dropArea) {
      return null
    }

    return (
      <div className={Style.dropBox}>
        <div>
          <Icon type='attachment' />
          <br />
          Add File(s)
        </div>
      </div>
    )
  }

  return (
    <div
      ref={questionBoxRef}
      className={wrapperClasses}
      onDragEnter={handleDropEnter}
      onDragLeave={handleDropLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onPaste={handlePaste}
      role='none'
    >
      {renderAddFileArea()}
      <div className={Style.questionInput}>
        {ChatTypeInput[chatType].focus && <FocusButton />}
        <FileAttachmentButton
          disabled={loading || false}
          fileUploadRef={fileUploadRef}
          handleFileChange={handleFileChange}
          chatType={ChatTypeEnum.GPT}
        />
        <SubmitButton chatType={ChatTypeEnum.GPT} fileUploadRef={fileUploadRef} imageUploadRef={imageUploadRef} />
      </div>
    </div>
  )
}

export default QuestionBox
