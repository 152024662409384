export const SUPPORT_EMAIL = 'interpreter.support@aurecongroup.com'
export const JIRA_SUPPORT_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/24'
export const ABOUT_URL = 'https://www.aurecongroup.com/expertise/environmental-management'
export const REQUEST_ACCESS_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/24'
export const MAX_FILE_NAME = 80
import { ContentTypeToRealFileExtn, FileMimeType } from '../enums/FileTypeEnum'

const config = {
  AUTH0_DOMAIN: 'code-dev.au.auth0.com',
  AUTH0_CLIENT_ID: 'tbvAEeNEa7LqpXkxEvKMlIr0rjYDpLJ6',
  AUTH0_AUDIENCE: 'https://codeinterpreter-dev.aurecongroup.digital',

  AUTH0_LOGOUT_RETURN: window.location.origin,
  MOBLIE_VIEW_MAX_WIDTH: 768,
}

export const PDF_EXT = [ContentTypeToRealFileExtn[FileMimeType.PDF]]
export const TXT_EXT = [ContentTypeToRealFileExtn[FileMimeType.TXT]]
export const IMAGE_EXT = [
  ContentTypeToRealFileExtn[FileMimeType.JPG],
  ContentTypeToRealFileExtn[FileMimeType.JPEG],
  ContentTypeToRealFileExtn[FileMimeType.PNG],
]
export const OFFICE_EXT = [
  ContentTypeToRealFileExtn[FileMimeType.PPT],
  ContentTypeToRealFileExtn[FileMimeType.DOC],
  ContentTypeToRealFileExtn[FileMimeType.XLS],
]
export const ASSISTANT_EXT = [
  ContentTypeToRealFileExtn[FileMimeType.CPP],
  ContentTypeToRealFileExtn[FileMimeType.C],
  ContentTypeToRealFileExtn[FileMimeType.CSV],
  ContentTypeToRealFileExtn[FileMimeType.DOCX],
  ContentTypeToRealFileExtn[FileMimeType.HTML],
  ContentTypeToRealFileExtn[FileMimeType.JAVA],
  ContentTypeToRealFileExtn[FileMimeType.JSON],
  ContentTypeToRealFileExtn[FileMimeType.MD],
  ContentTypeToRealFileExtn[FileMimeType.PDF],
  ContentTypeToRealFileExtn[FileMimeType.PHP],
  ContentTypeToRealFileExtn[FileMimeType.PPTX],
  ContentTypeToRealFileExtn[FileMimeType.PY],
  ContentTypeToRealFileExtn[FileMimeType.RB],
  ContentTypeToRealFileExtn[FileMimeType.TEX],
  ContentTypeToRealFileExtn[FileMimeType.TXT],
  ContentTypeToRealFileExtn[FileMimeType.TS],
  ContentTypeToRealFileExtn[FileMimeType.CSS],
  ContentTypeToRealFileExtn[FileMimeType.JS],
  ContentTypeToRealFileExtn[FileMimeType.JPG],
  ContentTypeToRealFileExtn[FileMimeType.JPEG],
  ContentTypeToRealFileExtn[FileMimeType.PNG],
  ContentTypeToRealFileExtn[FileMimeType.GIF],
  ContentTypeToRealFileExtn[FileMimeType.TAR],
  ContentTypeToRealFileExtn[FileMimeType.XML],
  ContentTypeToRealFileExtn[FileMimeType.XLSX],
  ContentTypeToRealFileExtn[FileMimeType.ZIP],
]

export const ATTACHMENT_EXT = [...ASSISTANT_EXT]
export const ATTACHMENT_FILETYPES = [
  FileMimeType.PDF,
  FileMimeType.TXT,
  FileMimeType.PNG,
  FileMimeType.JPG,
  FileMimeType.JPEG,
  FileMimeType.CSV,
  FileMimeType.DOCX,
  FileMimeType.CPP,
  FileMimeType.C,
  FileMimeType.HTML,
  FileMimeType.JAVA,
  FileMimeType.JSON,
  FileMimeType.MD,
  FileMimeType.PHP,
  FileMimeType.PY,
  FileMimeType.RB,
  FileMimeType.TEX,
  FileMimeType.CSS,
  FileMimeType.JS,
  FileMimeType.TAR,
  FileMimeType.TS,
  FileMimeType.XML,
  FileMimeType.ZIP,
  FileMimeType.GIF,
  FileMimeType.PPTX,
]
export const MAX_NO_ATTACHMENTS = 3
export const MAX_ATTACHMENT_SIZE = 1024 * 1024 * 20 // 20MB

export const UI_WIDTH_COLLAPSE = 770
export const UI_FULL_WIDTH = 1200

export const TOOLTIP_NO_OFFSET = { top: 0, left: 0 }

export default config
