import React, { FC } from 'react'
import classNames from 'classnames'
import Style from '../styles/Page.module.sass'
import Footer from './Footer'

export interface IPageProps {
  menu?: boolean
  footer?: boolean
  children?: JSX.Element[] | JSX.Element | React.ReactNode
}

const Page: FC<IPageProps> = (props) => {
  const pageClasses = classNames({
    [Style.page]: true,
  })

  return (
    <div className={pageClasses}>
      {/* {props.menu && <Menu />} */}
      <div className={Style.mainPageWrapper}>
        <div className={Style.pageContents}>{props.children}</div>
      </div>
      {props.footer && <Footer />}
    </div>
  )
}

export default Page
