import { postMultiFormDataAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { IBodyRequestModel } from '../models/IBodyRequestModel'
import { TokenExpiryWrapper } from './TokenManager'
import { ListResponseOf } from '@azure/openai-assistants'
import { IAssistantResponseModel } from '../models/IAssistantResponseModel'

export interface AssistantResponse {
  assistantId: string
  assistantResponse: ListResponseOf<IAssistantResponseModel>
}

export const processFileToAssistant = TokenExpiryWrapper(
  async (request: IBodyPostAssistantRequest): Promise<IResponse<AssistantResponse>> => {
    try {
      const formData = new FormData()
      formData.append('file', request.file)
      formData.append('message', request.message || '')

      return await postMultiFormDataAsync('/assistant', request.token ?? '', formData)
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)

interface IBodyPostAssistantRequest extends IBodyRequestModel {
  file: File
  message: string | null
}
